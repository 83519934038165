import React, { useState, useEffect } from "react";
import axiosClient from "../AxiosClient";
import { Footer, Header, AlertToRegistre, SearchDoctorCard, Spinner } from "../Components";
import "../Assets/Css/HomeCss/SearchDoctors.css";
import { useSelector } from "react-redux";
import TopBar from "../Components/TopBar";
import FooterTopBar from "../Components/FooterTopBar";

const SearchDoctors = () => {
  document.title = "Recherche Médecin";

  const AuthUserData = useSelector((state) => state.authUser);

  const [loading, setLoading] = useState(false);
  const [specialitiesList, setSpecialitiesList] = useState([]); // Holds the specialties list
  const [dataForm, setDataForm] = useState({
    address_cabinet: "",
    specialite: "", // Holds the selected specialty
  });
  const [dataSearch, setDataSearch] = useState([]);

  useEffect(() => {
    // Fetch specialties list on component mount
    axiosClient.get("/specialite/list")
      .then((res) => setSpecialitiesList(res.data.specialities)) // Ensure 'specialities' matches the backend key
      .catch((err) => console.error(err));
  }, []);

  const handleChangeData = (ev) => {
    const { name, value } = ev.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosClient.post("/search/doctors", dataForm)
      .then((res) => {
        setDataSearch(res.data.DataSearch || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const [showAlertToRegistre, setSowAlertToRegistre] = useState(AuthUserData.showAlertToAuth);

  return (
   
    <>
     {/* <TopBar/>  */}
      <Header />
      <section>
        <div className="_img_cover">
          <div className="search_bar">
          <form onSubmit={handleSubmitData}>
  <div className="search_input">
    <label htmlFor="Location" className="label_search">
      Location
      <i className="fas fa-map-marker-alt"></i>
    </label>
    <input
      id="Location"
      type="text"
      name="address_cabinet"
      className="input_search"
      placeholder="Choose Town"
      onChange={handleChangeData}
    />
  </div>

  <div className="search_input">
    <label htmlFor="Specialite" className="label_search">
      Specialty
      <i className="fas fa-stethoscope"></i>
    </label>
    <select
      id="Specialite"
      name="specialite"
      className="input_search"
      onChange={handleChangeData}
    >
      <option value="">Select Specialty</option>
      {specialitiesList.map((specialite, idx) => (
        <option key={idx} value={specialite}>{specialite}</option>
      ))}
    </select>
  </div>

  <button className="btn_search btn_bg_primary">Search</button>
</form>

          </div>
        </div>
      </section>
      <main className=" _container mt-4 mb-4 ">
        {loading && (
          <div className="w-full flex items-center justify-center mb-10">
            <Spinner />
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {dataSearch.length ? (
            dataSearch.map((info) => (
              <SearchDoctorCard
                key={info.id} // Use unique id for key
                name={`${info.firstname} ${info.lastname}`}
                id={info.id}
                day_debut_work={info.day_debut_work}
                day_fin_work={info.day_fin_work}
                specialite={info.specialite}
                available={info.available}
                avatar_doctor={info.avatar_doctor}
                time_debut_work={info.time_debut_work}
                time_fin_work={info.time_fin_work}
              />
            ))
          ) : (
            <p>No doctors found</p>
          )}
        </div>
      </main>
<FooterTopBar/>
      <Footer />
      <AlertToRegistre
        showAlertToRegistre={showAlertToRegistre}
        setSowAlertToRegistre={setSowAlertToRegistre}
      />
    </>
  );
};

export default SearchDoctors;
