import React, { useEffect, useState } from "react";
import { DataPicker, Footer, Header, TimePicker } from "../Components";
import ComplitedAppointment from "./ComplitedAppointment";
import {
  ClockIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router";
import axiosClient from "../AxiosClient";
import { useDispatch, useSelector } from "react-redux";
import { addUserData } from "../Redux/SliceAuthUser";
import { get } from "../Services/LocalStorageService";

const BookingAppointment = () => {
  const [selectedTime, setSelectedTime] = useState(""); // Time state
  const [SelectedDate, setSelectedDate] = useState("");
  const [SelectedType, setSelectedType] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showComplitedAppointment, setShowComplitedAppointment] =
    useState(false);

  const [FilePath, SetFilePath] = useState("");

  const { id } = useParams();
  const [DoctorData, setDoctorData] = useState({});

  const UserData = useSelector((state) => state.authUser);

  const [Loading, setLoading] = useState(true);

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value); // Update the time state
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  useEffect(() => {
    setLoading(true);

    if (
      UserData.isAuthenticated &&
      get("TOKEN_USER") &&
      UserData.user === null
    ) {
      axiosClient
        .get("/user")
        .then((re) => {
          dispatch(addUserData(re.data));
        })
        .catch((er) => {
          navigate("/connexion");
        });
    }

    axiosClient
      .get("/doctor/" + id)
      .then((re) => {
        setDoctorData(re.data[0]);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);
        console.log(er);
      });
  }, [dispatch, id, navigate, UserData.user, UserData.isAuthenticated]);

  const HandelSubmit = (e) => {
    e.preventDefault();

    const selectedDate = new Date(SelectedDate);
    selectedDate.setDate(selectedDate.getDate() + 1); // Adjusting date

    const formattedDate = selectedDate.toISOString().slice(0, 10);

    console.log(SelectedType);
    console.log(formattedDate);

    axiosClient
      .post("/take/appointment", {
        user_id: UserData.user.id,
        doctor_id: id,
        date_appointment: formattedDate,
        time_appointment: selectedTime, // Use the selected time
        type_appointment: SelectedType,
      })
      .then((res) => {
        SetFilePath(res.data.namefile);
        setShowComplitedAppointment(true);
      })
      .catch((err) => console.log(err));
  };

  if (!Loading) {
    return (
      <>
        <div className="absolute w-[100%] h-[100vh] img_bg">
          <div className="relative bg-black bg-opacity-75">
            <Header />
            <main className="h-[80.65vh] flex justify-center items-center">
              <div className="bg-white w-[33%] h-[69%] rounded-sm p-3">
                <div className="w-full">
                  <div className="flex justify-center w-full">
                    <div className="w-[25%]">
                      <img src="/img/Rectangle 3.png" alt="" />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <p className="uppercase font-semibold text-[14px] mt-3">
                      {DoctorData.firstname &&
                        DoctorData.firstname + " " + DoctorData.lastname}
                    </p>
                  </div>
                  <div className="flex justify-center mt-5">
                    <div>
                      <form onSubmit={HandelSubmit}>
                        <div className="flex justify-center">
                          {/* DATE PICKER */}
                          <div className="mr-4">
                            <label
                              htmlFor="date"
                              className="block mb-1 text-[13px] font-medium text-gray-900"
                            >
                              Select Date
                            </label>
                            <div className="relative max-w-sm">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  aria-hidden="true"
                                  className="w-5 h-5 text-gray-500"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </div>
                              <DataPicker setSelectedDate={setSelectedDate} />
                            </div>
                          </div>

                          {/* TIME PICKER */}
                          <div>
                            <label
                              htmlFor="time"
                              className="block mb-1 text-[13px] font-medium text-gray-900 dark:text-white"
                            >
                              Select Time
                            </label>
                            <div className="relative max-w-sm">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <ClockIcon className="w-5 h-5 text-gray-500" />
                              </div>
                              {DoctorData && (
                                <TimePicker
                                  DateAp={SelectedDate}
                                  minTime={DoctorData.time_debut_work}
                                  maxTime={DoctorData.time_fin_work}
                                  // stepInMinutes={DoctorData.appointment_time}
                                  idDoctor={id}
                                  onChange={handleTimeChange} // Pass the time change handler
                                  format="h:mm a"
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-center mt-4">
                          <div>
                            <label
                              htmlFor="type"
                              className="block mb-1 text-[13px] font-medium text-gray-900 dark:text-white"
                            >
                              Type Appointment
                            </label>
                            <div className="relative max-w-sm">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <ClipboardDocumentCheckIcon className="w-5 h-5 text-gray-500" />
                              </div>
                              <select
                                id="type"
                                onChange={handleTypeChange}
                                value={SelectedType}
                                className="bg-gray-50 pl-[44px] border border-gray-300 text-gray-900 text-[14px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-[7px]"
                              >
                                <option>Type Appointment</option>
                                <option value={"urgent"}>Urgent</option>
                                <option value={"nouveau patient"}>
                                  Nouveau Patient
                                </option>
                                <option value={"suivi"}>Suivi</option>
                                <option value={"diagnostic"}>Diagnostic</option>
                                <option value={"consultation"}>
                                  Consultation
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 flex justify-center">
                          <button className="text-white w-[37%] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[12px] px-4 py-2 text-center">
                            Take Appointment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer colorText="white" />
          </div>
        </div>
        <ComplitedAppointment
          showComplitedAppointment={showComplitedAppointment}
          setShowComplitedAppointment={setShowComplitedAppointment}
          FilePath={FilePath}
        />
      </>
    );
  } else {
    return (
      <div className="flex h-[100vh] w-full justify-center items-center">
        <div className="flex justify-center">
          <div>
            <img src="/img/Rectangle 3.png" alt="" />
          </div>
        </div>
      </div>
    );
  }
};

export default BookingAppointment;
