
import "../../Assets/Css/HomeCss/Footer.css";
import React from 'react';
// import './Footer.css';

const Footer = () => {
    return (
        


        <footer className="footer">
            <div className="container">
                <div className="footer-content">

                <div className="footer-section">
                        <h4>Follow Us</h4>
                        <ul className="social-links">
                            <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                            <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                            <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                            
                        </ul>
                        <p>Book appointments with the best<br></br>
                        Doctors and Specialists such as<br></br>
                        Gynecologists, Skin Speecialist,<br></br>
                        Child Specialists, Surgeons, etc in<br></br>
                        Pakistan conveniently.
                        </p>
                    </div>
                    <div className="footer-section">
                        <h4>About Us</h4>
                        <p>We are dedicated to providing the best service.</p>
                        <ul>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/careers">Careers</a></li>
                            <li><a href="/team">Our Team</a></li>
                        </ul>
                    </div>
                    
                    <div className="footer-section">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/services">Services</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>Contact Us</h4>
                        <ul>
                            <li><a href="tel:+123456789">+1 (234) 567-890</a></li>
                            <li><a href="mailto:info@example.com">info@example.com</a></li>
                            <li>123 Main Street, City, Country</li>
                        </ul>
                    </div>
                   
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 Your Company. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
