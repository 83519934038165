import React, { useState } from 'react';
import '../Assets/Css/HomeCss/topbar.css'; // Import your updated CSS file
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import ProfileImg from "../Components/Home/ProfileImg";
const TopBar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  
  return (
    
    <div className="topbar">
      
      <div className="topbar-left">
      <ProfileImg />
        <a href="https://facebook.com" className="topbar-icon" aria-label="Facebook">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://instagram.com" className="topbar-icon" aria-label="Instagram">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://twitter.com" className="topbar-icon" aria-label="X (Twitter)">
          <i className="fab fa-twitter"></i>
        </a>
      </div>
      <div className="topbar-right">
        <div className="dropdown">
          <button 
            className="topbar-button topbar-button-white dropdown-toggle" 
            onClick={toggleDropdown}
          >
            Login/Signup
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link to="/connexion" className="dropdown-item">Login</Link>
              <Link to="/identifier" className="dropdown-item">Signup</Link>
            </div>
          )}
        </div>
        
        <Link to="/doctor/signup" className="topbar-button topbar-button-blue">Join as a Doctor</Link>
       
      </div>
      
    </div>
  );
};

export default TopBar;
